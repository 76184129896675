/* * Used for top menu*/

.nav-tabls {
    border-bottom: none !important;
}

.nav-tabs .nav-link {
    padding: 10px !important;
}

.nav-tabs {
    border-bottom: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: transparent !important;
    border: 0 !important;
    color: #a03238 !important;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    border-bottom: none !important;

}

.nav-tabs .nav-link {
    border: none !important;
    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
}

.navbar-nav {
    flex-direction: unset !important;
}

.top-menu-border-line {
    border-bottom: 2px #a03238 solid;
}


