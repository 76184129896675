@import "~react-image-gallery/styles/css/image-gallery.css";


/* Font Sizes */
.app {
	overflow-y: visible;
}
.xsmall-font-size {
	font-size: 10px;
}

.small-font-size {
	font-size: 12px;
}

.normal-font-size {
	font-size: 14px;
}

.medium-font-size {
	font-size: 16px;
}

.big-font-size {
	font-size: 18px;
}

.mega-font-size {
	font-size: 20px;
}

.giga-font-size {
	font-size: 22px;
}

.login-form-bg-color {
	background-color: #0000004D !important;
}

.theme-bg-color {
	background-color: #a03238 !important;
}

/** Font color*/

.theme-font-color {
	color: #a03238 !important;
}

/** Font style */
.font-style {
	font-family: 'Poppins', sans-serif !important;
}

/* Login bg*/
.login-bg {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

}

.login-form-container {
	background: transparent linear-gradient(114deg, #FFFFFF1A 0%, #FFFFFF66 100%) 0% 0% no-repeat padding-box;
	box-shadow: inset 0px 8px 22px #00000033, 0px 11px 32px #0000004D;
	border: 1px solid #FFFFFF8C;
	border-radius: 40px;
	opacity: 1;
	backdrop-filter: blur(30px);
	-webkit-backdrop-filter: blur(30px);
}

.form-container {
	background: #00000061 0% 0% no-repeat padding-box;
	box-shadow: inset 0px 8px 22px #00000033, 0px 11px 32px #0000004D;
	border-radius: 0px 40px 40px 0px;
	opacity: 1;
}

.border-radius {
	border-radius: 20px !important;
}

.border-style {
	border: 2px solid #a03238 !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #fff !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #fff !important;
}

.MuiTypography-alignCenter {
	/* color: #fff !important; */
	color: #000 !important;
}

.MuiInputBase-input {
	color: #fff !important;
}

.MuiSelect-select.MuiSelect-select {
	color: #000 !important;
}

.black .MuiInput-underline:after {
	border-bottom: 2px solid #000 !important;
}

.black .MuiFormLabel-root.Mui-focused {
	color: #000 !important;
}

.black .MuiTypography-alignCenter {
	color: #000 !important;
}

.black .MuiInputBase-input {
	color: #000 !important;
}

.black .MuiSelect-select.MuiSelect-select {
	color: #000 !important;
}

.filter-black .MuiSelect-select.MuiSelect-select  select{
	color: #000 !important;
}

.add-product-upload-button-style {
	background: var(--unnamed-color-c1c1c3) 0% 0% no-repeat padding-box !important;
	background: #C1C1C3 0% 0% no-repeat padding-box !important;
	border-radius: 10px !important;
	opacity: 1 !important;
}

.pointer {
	cursor: pointer !important;
}

/**  Search input**/
.no-outline:focus {
	outline: none !important;
	box-shadow: none !important;
}

.input-style {
	border: 0 !important;
}

.editor-style {
	border: 1px solid #a03238 !important;
	border-radius: 20px !important;
}

.ql-toolbar.ql-snow {
	padding: 15px !important;
	border: none !important;
	border-bottom: none !important;
}

.ql-container.ql-snow {
	padding: 15px !important;
	border: none !important;
}

.avtar-style {
	background-color: #C1C1C3;
	width: '60px';
	height: '60px';
	font-size: '30px';
}

.product-gallery-container {
	position: relative;
	max-width: 400px;
	/* Maximum width */
	margin: 0 auto;
	/* Center it */
}

.product-gallery-container .product-gallery-content {
	border-radius: 20px !important;
	position: absolute;
	/* Position the background text */
	bottom: 0;
	/* At the bottom. Use top:0 to append it to the top */
	background: rgb(0, 0, 0);
	/* Fallback color */
	background: rgba(0, 0, 0, 0.5);
	/* Black background with 0.5 opacity */
	color: #f1f1f1;
	/* Grey text */
	width: 100%;
	/* Full width */
}

/* .printme {
	display: none;
} */

@media print {
	.no-printme {
		display: none  !important;
	}

	.printme {
		display: block !important;
	}
}


.alert-success {
    color: #155724 !important;
    background-color: #d4edda !important;
    border-color: #c3e6cb !important;
}

.alert {
    position: relative !important;
    padding: 0.75rem 1.25rem !important;
    margin-bottom: 1rem !important;
    border: 1px solid transparent !important;
    border-radius: 0.25rem !important;
}

.alert-danger {
    color: #721c24 !important;
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
}

.notification-read {
	background-color:#C1C1C3 !important;
}

