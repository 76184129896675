.MuiDataGrid-root .MuiDataGrid-columnsContainer {
    background-color: #C1C1C3 !important;
}

.MuiDataGrid-toolbarContainer {
    background-color: #C1C1C3 !important;
}

.MuiButton-textPrimary {
    color: #a03238 !important;
}


/* Loader modal */
.custom-modal {
    width: 100% !important;
    height: 100% !important;
}

*/ .modal-dialog {
    position: fixed !important;
    margin-top: 70 !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
}

.modal-backdrop-loader {
    background-color: #fff !important;
    opacity: 1 !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
    border-bottom: #a03238 2px solid !important;
}