.modal-content {
    border: 1px solid var(--unnamed-color-ffffff) !important;
    background: transparent linear-gradient(123deg, #FFFFFF1A 0%, #FFFFFF66 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    backdrop-filter: blur(15px) !important;
    -webkit-backdrop-filter: blur(15px) !important;

}