ul.timeline {
    list-style-type: none;
    position: relative;

}

/* ul.timeline:before {
    content: ' ';
    background: #a03238;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
    overflow: scroll;
}

ul.timeline>li {
    margin: 20px 0;
    padding-left: 20px;
}

ul.timeline>li:before {
    content: ' ';
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

ul li:last-child::before {
    border: 3px solid #a03238;
    background: white;
} */

ul.timeline li:before {
    content: ' ';
    background: #a03238;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
    overflow: scroll;
    top: 20px;
}

ul.timeline>li {
    margin: 20px 0;
    padding-left: 20px;
    position: relative !important;
}

ul.timeline>li:after {
    content: ' ';
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
    border: 1px solid #a03238;
    top: 0px;

}

ul li:last-child::after {
    border: 1px solid #a03238;
    background: #a03238;
}

ul li:last-child::before {
    /* border: 1px solid #a03238; */
    background: #fff;
}